<template>
    <div class="principal">
        <div class="page-header back-image7"></div>
        <div class="curve-white">
            <img src="../../../assets/img/curve.image.png" alt="">
        </div>

        <a-row type="flex" justify="center">
            <a-col class="a-col-mb">
                <h2 class="title-inicio">Filosofia</h2>
            </a-col>
            <div class="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1566344570248 vc_row-has-fill">
                <div class="wpb_column vc_column_container vc_col-sm-6">
                    <div class="vc_column-inner">
                        <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element ">
                                <div class="cont-text">
                                    <h1>Misión</h1>
                                    <p class="em-pf">La misión de la Empresa Municipal de Acueducto, Alcantarillado y
                                        Aseo de Silvia EMSILVIA ESP. Es satisfacer las necesidades de la
                                        comunidad Silvana en Materia de Acueducto Alcantarillado y Aseo
                                        en el casco urbano del Municipio de Silvia; velando por la
                                        calidad, la continuidad y la prestación de manera eficiente, de
                                        los servicios a su cargo , dentro del marco de la ley 142 de
                                        1.994, y sus decretos reglamentarios; generando rendimientos
                                        económicos que permitan la auto sostenibilidad de los servicios
                                        y la excelencia en la gestión de los mismos.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wpb_column vc_column_container vc_col-sm-6">
                    <div class="vc_column-inner">
                        <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element ">
                                <div class="cont-text">
                                    <h1>Visión</h1>
                                    <p class="em-pf">La vision de nuestra empresa es consolidarse como una de las
                                        mejores empresas de servicios públicos en Acueducto
                                        Alcantarillado y Aseo, a nivel departamental y Nacional,
                                        mejorando día a día, la gestión de los servicios en las áreas
                                        administrativa, operativa, comercial y financiera para que
                                        perdure por un tiempo indefinido; siempre deberá tener presente
                                        las políticas publicas, los planes de desarrollo y el plan
                                        básico de ordenamiento territorial con miras a cumplir con los
                                        planes de expansión y ampliación de la cobertura dentro del
                                        perímetro sanitario establecido por Planeación Municipal</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wpb_column vc_column_container vc_col-sm-6 column-image">
                    <div class="vc_column-inner">
                        <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element ">
                                
                                    <img class="cont-img" src="../../../assets/img/fotoPqr.png"
                                        alt="Descripción de la imagen">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a-row>
    </div>
</template>
<script>
export default {
    mounted() {

    }
}


</script>
<style lang="css">
.back-image7 {
    background: url('../../../assets/img/fotoTransparencia.png') no-repeat center;
    background-size: cover;
}



/* mision y vision */

.cont-text h1 {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -1px;
    font-weight: 900;
    color: var(--colorBluePrimary);
    margin: 0px 0px 10px 0px;
    font-family: inherit;
    border-style: solid;
    border-width: 0px 0px 0px 8px;
    border-color: #08C900;
    border-radius: 0px 0px 0px 0px;
    padding: 17px 17px 17px 17px
}

.vc_custom_1566344570248 {
    padding-top: 50px !important;
    padding-right: 50px !important;
    padding-bottom: 80px !important;
    padding-left: 50px !important;
}

.vc_row {
    margin-left: -15px;
    margin-right: -15px;
    margin: 0px 0px 80px 0px;
}

@media (min-width: 768px) {
    .vc_col-sm-6 {
        width: 50%;
        /* float: left; */
    }
}

.vc_col-sm-6 {

    position: relative;
    min-height: 1px;
    box-sizing: border-box;
}

.vc_row-has-fill>.vc_column_container>.vc_column-inner {
    padding-top: 35px;
}

.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.wpb_content_element {

    margin-bottom: 35px;
}

.wpb_text_column :last-child,
.wpb_text_column p:last-child {

    margin-bottom: 0;
    text-align: left;
}

.em-pf {
    margin: 0 0 26px;
    line-height: 1.6;
    font-weight: 700;

}

.vc_row:before,
.vc_row:after {
    content: " ";
    display: table;
}

.vc_row:after {
    clear: both;
}

/* imagen */
.cont-img {
    width: 1000%;
    border-radius: 13px 13px 13px 13px;
}

@media (min-width: 768px) {
    .column-image {
        float: right;
    display: block;
    margin-top: -500px;
    }

}
</style>